<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import type { FormWidgetData } from '../../../widgetDataTs.js';
  import contactForm from "../form-element/contact-form-base.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed, watch } from "vue";
  import { useTranslation } from "i18next-vue";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { useSiteLanguage } from "../../composables/use-site-language.js";
  import { GetComputedProperties, type CustomData } from '../../composables/use-custom-data-values.js';

  const { localeUrlPrefix } = useSiteLanguage({ callerLabel: "pricing-form" }); 

  const props = defineProps<{
    formSettingsKey: "pricing";
    widgetData: FormWidgetData<"ContactForm">;
    customData?: CustomData;
  }>();

  const { FormState } = useFormServices({ callerLabel: "pricingForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });

  watch(showUnlockedView, (newValue) => {
    if(newValue)
    {
      $('.form-header').hide(); // not quite sure I want to do this maybe add the header in the form? 
    }
  });
const { formHeading, secondFormHeading, multipleRowsLayout, CustomPostMessageHeader, CustomPostMessageBody } = GetComputedProperties(props.customData);

  const { t } = useTranslation();
</script>

<template>
  <template v-if="!showUnlockedView">
    <contactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :class="!multipleRowsLayout ? 'single-row' : ''" :custom-data="customData"> 
      <template #heading >
        <div class="pricing-title">
          {{ formHeading ? formHeading : "ABOUT YOU" }}
        </div>
      </template>
      <template #middleHeading>
        <div class="pricing-title" v-if="!multipleRowsLayout">
          {{ secondFormHeading ? secondFormHeading : "ABOUT YOUR BUSINESS" }}
        </div>
      </template>
    </contactForm>
  </template>
  <template v-else>
      <div class="complete">
        <h2 class="text-center">{{ CustomPostMessageHeader ? CustomPostMessageHeader :
          t(ContactFormPostKeysEnum.pricingThankYou, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}</h2>
        <p>{{ CustomPostMessageBody ? CustomPostMessageBody :
          t(ContactFormPostKeysEnum.pricingMessage, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}</p>
        <p class="text-center">
          <img class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
        </p>
        <p>{{ t(ContactFormPostKeysEnum.pricingRcStatement, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}<a :href="`${localeUrlPrefix}/resource-center/`" target="_top">{{ t(ContactFormPostKeysEnum.pricingRc, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}</a></p>
      </div>
  </template>
</template>                                                                                                                 